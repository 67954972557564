<template>
  <section>
    <header class="header">
    <a href="https://www.roskin.com.ua/">
        <img class="headerLogo" src="@/assets/logo.png" alt="">
    </a>

    <div class="divided"></div>

    <div class="headerMenu">
      <main-menu class="menu"></main-menu>
    </div>

    <div class="divided"></div>

    <div class="headerTop">
      <div class="emaiBox">
        <img src="@/assets/icons/mail.svg" alt="">
        <p>roskin.o.r@gmail.com</p>
      </div>

      <div class="phoneBox">
        <img src="@/assets/icons/phone.svg" alt="">
        <a href="tel:+380665246345">066 524 63 45</a>
      </div>
    </div>
    </header>
  </section>
</template>

<script>

export default {
  name: 'HeaderSite',
  components: {
   
  }
}
</script>

<style scoped>

.headerLogo {
  max-width: 180px;
}

.divided {
  width: 0.5px;
  height: 70px;
  background: #ffffff;
}

a {
  text-decoration: none; 
  color: #ffffff;
}

  .headerTop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    gap: 10px;
    flex-shrink: 0;
    flex-wrap: wrap;
  }

  .emaiBox, .phoneBox {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .emaiBox p, .phoneBox a {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }

  .emaiBox img, .phoneBox img {
    width: 32px;
    height: 32px;
  }

  .socialLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .socialLinks a img {
    width: 32px;
  }


  .header {
    position: fixed;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 15px;
    background:#322929;
    color: white;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
  }

  .menu {
    color: white;
  }
  

  .headerLogo {
    width: 220px;
    height: 80px;
  }

    .sn {
      color: #ffffff;
    }


    @media (max-width: 430px) {
      .header {
        display: none;
      }
    }
</style>