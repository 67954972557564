<template>
  <section class="menuHamburger">
    <SlideMenu :isOpen="isOpen" @closeMenu="isOpen = false" class="burgerMenu" width="390" >
      <img class="headerLogo" src="@/assets/logo.png" alt="">
      <router-link to="/" v-if="!isOpen" @click="isOpen=true">
          <a href="">Головна</a>
        </router-link>

        <router-link to="/about" v-if="!isOpen" @click="isOpen=true">
          <a href="">Про нас</a>
        </router-link>

        <router-link to="/portfolio" v-if="!isOpen" @click="isOpen=true">
          <a href="">Приклади робіт</a>
        </router-link>

        <router-link to="/reviews" v-if="!isOpen" @click="isOpen=true">
          <a href="">Відгуки</a>
        </router-link>

        <router-link to="/calculator" v-if="!isOpen" @click="isOpen=true">
          <a href="">Калькулятор</a>
        </router-link>

        <router-link to="/now" v-if="!isOpen" @click="isOpen=true">
          <a href="">Проекти в роботі</a>
        </router-link>

        <router-link to="/fasting" v-if="!isOpen" @click="isOpen=true">
          <a href="">Відділ постачання</a>
        </router-link>

        <router-link to="/contacts" v-if="!isOpen" @click="isOpen=true">
          <a href="">Контакти</a>
        </router-link>

        <div class="emaiBox">
          <img src="@/assets/icons/mail.svg" alt="">
          <p>info@gmail.com</p>
        </div>

        <div class="phoneBox">
          <img src="@/assets/icons/phone.svg" alt="">
          <a href="tel:+380665246345">066 524 63 45</a>
          <a href="tel:+380735481288">073 548 12 88</a>
        </div>

        <div class="socialLinks">
          <a href="https://t.me/roskinbud" target="_blank" rel="noopener">
            <img class="sn" src="@/assets/icons/whiteIcons/telegram-icon.svg" alt="">
          </a>
          <a href="https://www.facebook.com/olegroskinbud" target="_blank" rel="noopener">
            <img class="sn" src="@/assets/icons/whiteIcons/facebook-icon.svg" alt="">
          </a>
          <a href="https://www.instagram.com/olegroskin/" target="_blank" rel="noopener">
            <img class="sn" src="@/assets/icons/whiteIcons/instagram-icon.svg" alt="">
          </a>
        </div>
    </SlideMenu>
  </section>
</template>

<script>
import  SlideMenu from '@/components/menu/SlideMenu.vue'   // импортируйте переходы CSS, которые вы хотите использовать, в данном случае мы используем `Slide`

export default {
  name: 'PhoneMenu',
  components: {
    SlideMenu
  },
  data() {
    return {
      isOpen: false,

    }
  }
}
</script>

<style scoped>
  .headerLogo {
    width: 220px;
  }

  a {
    text-decoration: none;
    color: white;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
  }

  .socialLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .socialLinks img {
    width: 40px;
  }

  .emaiBox, .phoneBox {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .emaiBox p, .phoneBox a {
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .emaiBox img, .phoneBox img {
    width: 32px;
    height: 32px;
  }


</style>