<template>
  <section class="accordionBlock">
    <section class="faq" id="faq">
      <div class="container faq__container">
        <h2 class="faq__title"><span>Знаємо, що хвилює замовників перед початком робіт:</span> <br>Відповіді на поширені запитання</h2>
        <div class="faq__items">
          <div class="item" v-for="(item, index) in faqItems" :key="index">
            <div class="item__header" @click="toggleAccordion(index)">
              <div class="item__question">{{ item.question }}</div>
              <div class="item__arrow" :class="{ 'expanded': item.expanded }">
                <i class="fas fa-chevron-down"></i>
              </div>
            </div>
            <div class="item__answer" v-if="item.expanded">{{ item.answer }}</div>
          </div>
        </div>
      </div>
    </section>

    <div class="telegramLink">
      <p><span>Підпишіться на наш телеграм канал,</span> де ми розповідаємо про ремонт та будівництво, а також ділимось етапами виконання робіт наших клієнтів</p>
      <a href="https://t.me/roskinbud">
        <img src="@/assets/icons/TelegramLink.svg" alt="">
      </a>
    </div>
  </section>
 
</template>


<script>
import { ref } from 'vue';
import '@fortawesome/fontawesome-free/css/all.css';



export default {
  name: 'AccordionBlock',
  setup() {
    const faqItems = ref([
        {
          question: "Якими будівельними проектами ваша компанія займалася раніше?",
          answer: "Наша компанія має багаторічний досвід у виконанні різноманітних будівельних проектів. Ми займалися будівництво та ремонт приватних будинків , будівництво та оздоблювальні роботи ТЦ Гулівер, будівництво ЖК Комфорт Таун, ЖК МІністерський,  ремонтні роботи офісних та житлових приміщень. Ми пишаємося успішними завершеннями цих проектів та готові розглянути нові цікаві виклики.",
          expanded: true,
        },
        {
          question: "Як ви плануєте графік та строки виконання будівельних робіт?",
          answer: "Ми плануємо графік та строки виконання будівельних робіт з урахуванням різноманітних факторів, таких як обсяг робіт, доступність матеріалів, погодні умови та інші важливі фактори. Наш підхід полягає в тому, щоб створити реалістичний та деталізований план, який враховує всі вимоги проекту та відповідає стандартам якості. Ми регулярно взаємодіємо з клієнтом, щоб забезпечити, що графік відповідає їхнім очікуванням, і маємо систему звітності для відстеження прогресу на будівельному майданчику.",
          expanded: false,
        },
        {
          question: "Як ви формуєте бюджет для будівельного проекту?",
          answer: "Формування бюджету для будівельного проекту - це комплексний процес, в якому ми враховуємо різноманітні аспекти. Спочатку ми детально вивчаємо проект та його вимоги, зокрема обсяг робіт, вибір матеріалів, технічні характеристики та інші фактори. Після цього ми рахуємо вартость матеріалів та роботи, а також враховуємо можливі додаткові витрати та резерви для уникнення неочікуваних ситуацій. І ми готові надати детальний розгорнутий розрахунок бюджету клієнту, щоб вони могли все чітко зрозуміти.",
          expanded: false,
        },
        {
          question: "Які матеріали ви використовуєте у своїх будівельних проектах?",
          answer: "Наша компанія активно веде пошук високоякісних будівельних матеріалів за конкурентоспроможними цінами. Ми співпрацюємо з надійними постачальниками та ретельно аналізуємо ринок, щоб забезпечити оптимальний баланс якості та вартості для наших клієнтів. Наш підхід до вибору матеріалів полягає в тому, щоб забезпечити високу якість, дотримуючись при цьому ефективності та економічності. Ми завжди готові знаходити найкращі рішення для наших клієнтів.",
          expanded: false,
        },
        {
          question: "Як ви здійснюєте контроль якості виконання робіт?",
          answer: "В якості керівника компанії, я відданий особистому контролю за якістю виконання робіт на всіх етапах будівельного процесу. Я взаємодію з нашою командою фахівців та інженерів, надаючи конкретні вказівки та стежачи за виконанням стандартів якості. Регулярні інспекції та аудити на майданчиках - це стандартна практика, щоб забезпечити відповідність наших робіт високим стандартам якості. Моя особиста участь у контролі якості виконання робіт є гарантією того, що кожен проект відповідає найвищим стандартам, які ми встановлюємо для себе та наших клієнтів.",
          expanded: false,
        },
        {
          question: "Як ви плануєте вести комунікацію з клієнтом під час будівельних робіт?",
          answer: "Ми прагнемо забезпечити максимально зручний та ефективний спосіб комунікації для наших клієнтів. З цією метою ми створюємо чат у зручному месенджері, де присутній як замовник, так і спеціалісти нашої компанії. Кожен тиждень ми висилаємо фотографії об'єкту, щоб клієнт міг відслідковувати прогрес робіт. У цьому чаті також можна задавати питання, отримувати відповіді та приймати спільні рішення. Ми вважаємо, що такий підхід дозволяє нам миттєво реагувати на будь-які питання чи зміни, а також підтримує відкриту та ефективну комунікацію протягом усього процесу будівництва.",
          expanded: false,
        },
        {
          question: "Як ви контролюєте бюджет та витрати на проекті?",
          answer: "Розуміючи динаміку будівельного процесу, ми визнаємо, що неможливо передбачити всі витрати напередодні проекту. Зміни у вартості матеріалів, непередбачені зміни в проекті та додаткові роботи - це стандартні реальності будівництва. Однак ми дотримуємося прозорого підходу до контролю витрат. Щоразу, коли з'являється можливість змін у вартості, ми негайно повідомляємо замовника, надаємо детальну інформацію про причини та обговорюємо варіанти управління бюджетом. Зміни вартості вводяться лише після отримання згоди та узгодження замовника, щоб забезпечити максимальну прозорість та взаєморозуміння в управлінні фінансами проекту.",
          expanded: false,
        },
        {
          question: "Які умови співпраці та договірні відносини?",
          answer: "Умови співпраці та договірні відносини є важливою складовою нашого професійного підходу. Наша команда зазвичай визначає деталі угоди в індивідуальному порядку, враховуючи особливості кожного проекту та побажання клієнта. Однак тут деякі загальні принципи, які ми застосовуємо: Оплата: Визначається структура оплати, частота та умови здійснення платежів. Ми намагаємося створити гнучкі умови, щоб вони відповідали індивідуальним потребам клієнта. Зміни в проекті: Регламентується процедура внесення змін в проект, включаючи оцінку вартості та термінів. Ми прагнемо зробити цей процес максимально прозорим та зрозумілим для обох сторін. Терміни виконання: Визначаються чіткі та реалістичні терміни виконання робіт, з урахуванням можливих факторів, що можуть впливати на графік. Гарантії: Встановлюються умови гарантійного обслуговування та відповідальності за можливі недоліки. Наша мета - створити прозорі, справедливі та взаємовигідні умови співпраці, які відповідають потребам та очікуванням клієнта",
          expanded: false,
        },
        {
          question: "Які гарантії надаєте на свої роботи?",
          answer: "Ми надаємо 24 місяці гарантії на виконані роботи, які охоплюють різні аспекти проекту.",
          expanded: false,
        },
    ]);

    const toggleAccordion = (index) => {
      // Ваша логика для раскрытия и закрытия элементов
      faqItems.value[index].expanded = !faqItems.value[index].expanded;
    };

    return {
      faqItems,
      toggleAccordion,
    };
  },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.faq {
  padding: 20px;
}

.faq__container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: #322929;
}

.faq__title {
  font-size: 24px;
  margin-bottom: 20px;
  color: white;
}

.faq__title span {
  color: #ED7C0E;
}

.faq__items {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  
}

.item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  cursor: pointer;
  margin-bottom: 10px;
  
}

.item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.item__question {
  font-size: 18px;
  color: white;
}

.item__arrow {
  font-size: 18px;
  color: white;
}

.item__arrow.expanded {
  transform: rotate(180deg);
}

.item__answer {
  padding: 10px 0;
  font-size: 16px;
  color:#ED7C0E;
  margin-top: 20px;
  padding: 5px;
}

.item.expanded .item__answer {
  display: block;
  background: #ECF1F8;
}

.item__arrow i {
  font-size: 18px;
  color: white;
}

.item__arrow.expanded i {
  transform: rotate(180deg);
}

.item__arrow {
  font-size: 18px;
  color: white;
}

.item__arrow.expanded i {
  transform: rotate(180deg);
}

.telegramLink {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.telegramLink p {
  width: 500px;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
}

.telegramLink p span {
  color:  #ED7C0E;
}

.telegramLink img {
  width: 100px;
  height: 100px;
  color: white;
  background: #322929;
}

.accordionBlock {
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
  margin: 50px 100px;
  padding: 15px;
  background: #322929;
  border-radius: 2px;
}


@media (max-width: 430px) {
  .telegramLink p {
  width: 300px;
  }

  .accordionBlock {
    margin: 30px 0;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
  }

  .faq__container {
    max-width: 350px;

  }

}

@media (min-width: 431px) and (max-width: 1024px) {
  .telegramLink p {
  width: 500px;
  }

  .accordionBlock {
    margin: 30px 20px;
    padding-bottom: 15px;
    align-items: center;
    justify-content: center;
  }

  .faq__container {
   max-width: 500px;
  }
}
</style>
