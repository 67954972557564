<template>
  <section class="mainScreen" v-scroll="handleScroll">

    <div class="titleBox">
      <h1 class="title">Дизайнерський ремонт <span id="typing"></span></h1>
      
      <div class="qualityBtns">
        <div class="qItems"> 
          <div class="circle"></div>
          <p class="animate__animated animate__pulse">Під ваш бюджет</p>
        </div>

        <div class="qItems"> 
          <div class="circle"></div>
          <p class="animate__animated animate__pulse">Безкоштовний виїзд спеціаліста</p>
        </div>

        <div class="qItems"> 
          <div class="circle"></div>
          <p class="animate__animated animate__pulse">Платіть після готовності робіт</p>
        </div>
    </div>
    </div>

    <div class="formMainScreen animate__animated animate__fadeInLeft">
      <p>Отримайте перелік цін на ваш телефон</p>
      <MainForm2></MainForm2>
    </div>
  </section>
</template>

<script>
import Typed from 'typed.js';
import 'animate.css';

export default {
  name: 'MainScreen',
  mounted() {
    const options = {
    strings: ["за 70 днів під ключ з гарантією"],
    typeSpeed: 20,
    backDelay: 500,
    startDelay: 500,
    loop: true,
    loopCount: 1,
    contentType: 'html5',
    showCursor: false,
  };

  new Typed('#typing', options);
  },
  methods: {
    handleScroll(el, binding) {
      const options = binding.value || {};
      const rect = el.getBoundingClientRect();

      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        el.classList.add(options.animationClass || 'your-animate-css-class');
      } else {
        el.classList.remove(options.animationClass || 'your-animate-css-class');
      }
    },
  },


}
</script>

<style scoped>

.circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ED7C0E;
  margin-right: -10px;
  z-index: 20;
}

.qItems {
  display: flex;
  background: rgba(50, 41, 41, 0.4);
  border-radius: 30px 0 0 30px;
}

  .mainScreen {
    background: linear-gradient(transparent 100px, white), url('@/assets/background.webp') no-repeat fixed center ;
    background-size: cover;
    padding: 150px 100px;
    width: 100%;
  }

  .titleBox {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 105px;
    margin-bottom: 155px;
  }


  .title {
    color: #ffffff;
    font-size: 56px;
    font-weight: 900;
    width: 980px;
    min-height: 126px;
  }

  .qualityBtns {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .qualityBtns p{
    color: white;
    padding: 15px 20px;
    border-radius: 2px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }

  .formMainScreen {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: white;
  }



  .formMainScreen p {
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    width: 700px;
  }

  MainForm button {
    padding: 0 50px;
  }


  @media (max-width: 430px) {
    .mainScreen {
    padding: 20px 20px;
    background: url('@/assets/background.webp') no-repeat;
    background-position: top;
    margin: 0;
    box-sizing: border-box;

  }

  .titleBox {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .subtitle {
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .title {
    text-align: center;
    font-size: 28px;
    text-transform: uppercase;
    width: 341px;
  }

  .qualityBtns {
    flex-direction: column;
    gap: 15px;
  }


  .formMainScreen {
    align-items: center;
    justify-content: center;
  }

  .formMainScreen p {
    text-align: center;
    width: 340px;
  }

  }

  @media (min-width: 431px) and (max-width: 1024px) {


  .titleBox {
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .subtitle {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .title {
    text-align: center;
    font-size: 40px;
    text-transform: uppercase;
    width: 713px;

  }

  .qualityBtns {
    align-items: center;
    justify-content: center;
    gap: 30px;
  }


  .formMainScreen {
    gap: 30px;
    align-items: center;
    justify-content: center;
  }

  .formMainScreen p {
    text-align: center;
  }
  }

</style>