<template>
  <footer class="footer">
    <div class="div1"> 
      <img src="@/assets/logo.png" alt="logo" class="logo">
      <p class="confidence">Політика конфеденційності</p> 
      <div class="socialLinks">
        <a href="https://t.me/roskinbud" target="_blank" rel="noopener">
          <img class="sn" src="@/assets/icons/whiteIcons/telegram-icon.svg" alt="">
        </a>
        <a href="https://www.facebook.com/olegroskinbud" target="_blank" rel="noopener">
          <img class="sn" src="@/assets/icons/whiteIcons/facebook-icon.svg" alt="">
        </a>
        <a href="https://www.instagram.com/olegroskin/" target="_blank" rel="noopener">
          <img class="sn" src="@/assets/icons/whiteIcons/instagram-icon.svg" alt="">
        </a>
      </div>     
    </div>

    <div class="div2">
      <p class="connectText">Звʼяжіться з нами будь-яким зручним способом</p>
      <div class="emaiBox">
        <img src="@/assets/icons/mail.svg" alt="">
        <p>roskin.o.r@gmail.com</p>
      </div>

      <div class="phoneBox">
        <img src="@/assets/icons/phone.svg" alt="">
        <a href="tel:+380665246345">066 524 63 45</a>
        <a href="tel:+380735481288">073 548 12 88</a>
      </div>
    </div>

    <div class="div4"> 
      <menu class="menuFooter">
        <router-link to="/">
          <a href="">Головна</a>
        </router-link>

        <router-link to="/about">
          <a href="">Про нас</a>
        </router-link>

        <router-link to="/portfolio">
          <a href="">Приклади робіт</a>
        </router-link>

        <router-link to="/reviews">
          <a href="">Відгуки</a>
        </router-link>

        <router-link to="/calculator">
          <a href="">Калькулятор</a>
        </router-link>

        <router-link to="/now">
          <a href="">Проекти в роботі</a>
        </router-link>

        <router-link to="/fasting">
          <a href="">Відділ постачання</a>
        </router-link>

        <router-link to="/contacts">
          <a href="">Контакти</a>
        </router-link>
      </menu>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterSite'
}
</script>

<style scoped>

a {
  text-decoration: none; 
  color: #322929;
}
  .footer {
    display: flex;
    gap: 50px;
    align-items: top;
    justify-content: center;
    padding: 50px 50px;
    background: #322929;
    color: white;
  }

  .logo {
    width: 220px;
  }

  .title {
    max-width: 220px;
    color:  #ECF1F8;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .confidence {
    color: #ECF1F8;
    font-size: 16px;
    font-weight: 500;
  }

  .connectText {
    max-width: 220px;
    color:  #ECF1F8;
    font-size: 16px;
    font-weight: 700;
  }


  .emaiBox, .phoneBox {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .emaiBox p, .phoneBox a {
    font-size: 14px;
    font-weight: 400;
    color: #ECF1F8;
  }

  .emaiBox img, .phoneBox img {
    width: 32px;
    height: 32px;
  }

  .socialLinks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .socialLinks a img {
    width: 32px;
    height: 32px;
  }

  .menuFooter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 350px;
    gap: 25px;
    
  }

  menu a {
    color: white;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
  }

  menu a:hover {
    color: #ED7C0E;
  }

.div1, .div2, .div3 {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media (max-width: 430px) {
  .footer {
    padding: 30px 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 0;
  }

  .div4 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }

  .div1, .div2, .div3 {
    align-items: center;
    justify-content: center;
  }

  .menuFooter {
    display: none;
    
  }
}

@media (min-width: 431px) and (max-width: 1024px) {
  .div2 {
    display: none;
  }
}
</style>